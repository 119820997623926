<template>
<div id="app">
  <loading :active.sync="loading"></loading>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card>
            <div slot="header">
              <div class="row">
              <div class="col-md-6">
                <strong>Anotações ({{ notes.length }})</strong>
              </div>
              <div class="col-md-6" style="text-align:right;">
                <b-button variant="warning" size="sm" style="color:white;" v-on:click="isHidden = !isHidden">Nova anotação</b-button>
                &nbsp;&nbsp;
                <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
              </div>
              </div>
            </div>
          <div class="form" v-if="!isHidden">
            <div class="row">
              <div class="col-md-3">
                </div>
                <div class="col-md-6">
            <div class="form-group">
              <label>Título</label>
              <input class="form-control" type="text" v-model="note.title" required>
            </div>
            <div class="form-group">
              <label>Descrição</label>
              <textarea rows="3" class="form-control" v-model="note.text" required></textarea>
            </div>
            <button class="btn btn-primary" @click="addNote">Salvar anotação</button>
            <div class="alert alert-danger text-center" role="alert" v-bind:class="{ 'd-none': !isActive }">Todos os campos são obrigatórios</div>
                </div>
                <div class="col-md-3">
                </div>
                </div>
          </div>
          <div class="row" style="margin-top:20px;">
            <div class="col-sm-4 note" v-for="(note, index) in notes" :key="index">
              <b-card style="background-color:lightyellow;">
                <button class="btn btn-link" v-b-modal="'modal-editar-' + index"><CIcon name="cil-pencil" /></button>
                <button class="close" @click="removeNote(index)">&times;</button>
                <b-card-body>
                  <h4 class="card-title">{{note.title}}</h4>
                  <p class="card-text" :inner-html.prop="note.text | nl2br"></p>
                  <div style="text-align:right;"><small>
                  <em class="card-subtitle mb-2 text-muted">{{note.date | formataData}}</em>
                  </small></div>
                </b-card-body>
              </b-card>
              <b-modal :id="'modal-editar-' + index" hide-footer title="Editar anotação">
                <div class="form-group">
                  <label>Título</label>
                  <input class="form-control" type="text" v-model="notes[index].title" required>
                </div>
                <div class="form-group">
                  <label>Descrição</label>
                  <textarea rows="3" class="form-control" v-model="notes[index].text" required></textarea>
                </div>
                <button class="btn btn-primary" @click="editNote(index)">Salvar anotação</button>
              </b-modal>
            </div>
          </div>
          </b-card>
          <b-modal size="lg" id="ajuda" hide-footer title="Anotações">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/eXBTmgWYPLw?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'

export default {
  data () {
    return {
      isActive: false,
      title: 'Anotações',
      subtitle: '',
      note: {
        title: '',
        text: ''
      },
      notes: [],
      isHidden: true,
      loading: false
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.loading = true
    this.verificaSessao()
  },
  filters: {
    formataData: function (num) {
      if (typeof num !== 'undefined') {
        const d = new Date(num)
        //const ye = new Intl.DateTimeFormat('pt-BR').format(d)
        const ye = d.toLocaleString('pt-BR')
        return ye
      }
    },
    nl2br (str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
          return ''
      }
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>'
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
    }
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.recarregar()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    recarregar: function () {
      this.notes = []
      this.listar('gp_Metadados', 'date_created', '(module LIKE anotacao)').then(
        (r) => {
          for (var i = 0; i < r.length; i++) {
            this.notes.push({ id: r[i].id, title: r[i].string, text: r[i].description, date: r[i].date_created })
          }
          this.loading = false
        },
        (r) => {
          this.notes = []
          this.loading = false
        }
      )
    },
    addNote() {
      let {
        text,
        title
      } = this.note
      this.loading = true
      if (this.note.text.length > 1 && this.note.title.length > 1) {
        this.inserir('gp_Metadados', {resource: [ {string: this.note.title, module: 'anotacao', description: this.note.text, created_by: this.$session.get('user_ID')} ]}, '', '1').then(
          (rr) => {
            var newID = rr.id
            this.notes.push({
              id: newID,
              text: text,
              title: title,
              date: new Date(Date.now()).toLocaleString()
            })
            this.isActive = false
            this.note.text = ""
            this.note.title = ""
            this.loading = false
          },
          (rr) => {
            this.isActive = false
            this.note.text = ""
            this.note.title = ""
            this.recarregar()
            this.loading = false
          }
        )
      } else {
        this.loading = false
        this.isActive = true
      }
    },
    editNote(index) {
      if (this.notes[index].text.length > 1 && this.notes[index].title.length > 1) {
        this.atualizar('gp_Metadados', {resource: [ {string: this.notes[index].title, module: 'anotacao', description: this.notes[index].text} ]}, 'id = ' + this.notes[index].id, '', '1')
        this.$bvModal.hide('modal-editar-' + index)
      } else {
        alert('Todos os campos são obrigatórios')
      }
    },
    removeNote(index) {
      if (confirm('Tem certeza?')) {
        this.remover('gp_Metadados', 'id = ' + this.notes[index].id)
        this.notes.splice(index, 1)
      } else {
        return false
      }
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  },
  components: {
    Loading,
    VueAutonumeric
  }
}
</script>